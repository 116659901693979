<script>

import { audit, inarr, RegexHelper } from '@/common';
import { UiError } from '@/common/errors';
import * as icons from '@/icons';


class UiIconError extends UiError {

}


export default {
  name: 'UiIcon',

  props: {
    filename: {
      type: String,
      required: true
    },
    color: {
      type: [String, Number],
      default: 'black'
    },
    size: {
      type: [String, Number],
      default: 24
    },
  },

  data() {
    return {
      colors: {
        black: '#000000',
        green: '#409488',
      }
    }
  },

  computed: {
    svg() {
      let result = icons[this.filename] ?? icons.error;

      result = result.replaceAll('{FILL}', this.svgColor);

      return result;
    },
    svgColor() {
      const colorName = this.color;
      const colorHex = this.color;
      const colorByName = this.colors[colorName];

      if (colorByName) return this.hex(colorByName);

      return this.hex(colorHex);
    },
    style() {
      return `width: ${this.size}px; height: ${this.size}px;`;
    },
  },

  methods: {
    unhash(val) {
      if (typeof val !== 'string') return val;

      if (!val.startsWith('#')) return val;

      return val.slice(1);
    },
    hashed(val) {
      if (!inarr(typeof val, 'string', 'number'))
        throw new UiIconError(`HEX in type of ${typeof val} is not supported. Only supported types: string, number.`);

      return `#${val.padStart(6, '0')}`;
    },
    hex(val) {
      let result = this.unhash(val);
      result = this.hashed(result);

      if (!result.match(RegexHelper.hex))
        throw new UiIconError(`The given value is not HEX: ${audit(val)}`);

      return result;
    },
  },
}

</script>

<template>
  <div class="ui_icon" v-html="svg" :style="style" />
</template>

<style scoped lang="scss">

@import "public/index.scss";

.ui_icon {
  flex-shrink: 0;

  :deep(svg) {
    width: 100%;
    height: 100%;
  }
}

</style>
