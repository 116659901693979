import { createStore } from 'vuex';
import main from '@/store/main';
import country from '@/store/country';

const store = createStore({
    modules: {
      main,
      country
    },
    strict: process.env.DEBUGGING,
  });


export default store;