import { detectCountryConfig } from '@/helpers/index.js';

const {  country } = detectCountryConfig();

export default {
  namespaced: true,
  state: {
    country,
  },
  getters: {
    countryCode: (state) => state.country,
  },
};