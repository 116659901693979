<script>

import UiIcon from '@/uikit/UiIcon.vue';

export default {
  name: 'BottomBar',

  components: { UiIcon },

  methods: {
    onClick(item) {
      this.$emit('select', item?.value ?? null);
    },
  },
  data() {
    return {
      items: [
        { name: 'bottomBar.try', value: 'try', icon: 'checkMarkRounded' },
        { name: 'bottomBar.connectWithUsShortTitle', value: 'contact', icon: 'phone' },
        { name: 'bottomBar.shortLogin', value: 'auth', icon: 'door' },
      ],
    }
  },
}

</script>

<template>
  <div class="landing_bottom_bar">
    <div
      v-for="(item, i) of items"
      :key="i"
      class="landing_bar_item"
      @click="onClick(item)"
    >
      <UiIcon
        class="landing_bar_item_icon"
        :filename="item.icon"
      />

      <div class="landing_bar_item_name">
        {{ $t(item.name) }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "public/index.scss";
.landing_bottom_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 80px;
  padding: 0 13px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: $cWhite;
  box-shadow: -5px 2px 50px 0 rgba($cBlack, 0.07);
  z-index: 1;

  .landing_bar_item {
    @include ui-button-pd;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px 10px;
    box-sizing: content-box;
    border-radius: 6px;
    cursor: pointer;

    .landing_bar_item_name {
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
@media (min-width: 992px) {
  .landing_bottom_bar {
    display: none;
  }
}

</style>
