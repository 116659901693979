export class RegexHelper {
  static hex = /^#[a-zA-Z0-9]{6}$/;
  static path = /^\/[a-zA-Z0-9]+$/;
}

export function inarr(val, ...arr) {
  return arr.includes(val);
}

export function audit(val) {
    switch (typeof val) {
      case 'string':
        return `'${val}'`;
      case 'object':
        return JSON.stringify(val);
      default:
        return String(val);
    }
  }