

// Base errors

export class CommonError extends Error {
    constructor(message, stack = null) {
      super(message);

      if (stack) this.stack = stack;
    }
}

export class NotifiedError extends CommonError {
    constructor(message, stack = null) {
        super(message, stack);
    }
}


// Specific errors

export class NavigationError extends NotifiedError {

}

export class PotentialLoopError extends NotifiedError {
    constructor(message = '') {
        super('Possible loop detected!');
        if (message) this.message += ' '+message;
    }
}

export class UiError extends CommonError {
    constructor(message, stack = '') {
        super(message);
        this.stack = stack;
    }
}

// UI Kit errors

export class UiNotifiedError extends NotifiedError {

}

export class UiIconError extends UiError {

}

